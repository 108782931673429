@import "../../../../styles/variables.less";

.project-modal-session {
    &__title {
        font-size: 28px !important;
        font-weight: 500;
        color: @dark;
    }

    &__subtitle {
        margin: 0px 0px 20px 0px !important;
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;