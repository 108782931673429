@import "../../styles/variables.less";

.user-area {
    position: relative;
    width: 980px !important;
    height: 725px;
    top: 50% !important;
    transform: translateY(-50%) !important;
    padding-bottom: 0;
    
    .ant-modal {
        &-content {
          padding: 32px !important;
          border-radius: 4px !important;
        }
        &-body {
            margin: 24px 0;
            display: flex;
        }
    }

    &-components {
        display: flex;
        flex-direction: column;
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;