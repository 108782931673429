.graphic {
    gap: 32px;

    &__chart {
        margin-bottom: 32px;
        height: 382px !important;
        width: 100% !important;
    }
}

.legend {
    padding-left: 53px;
    padding-right: 6px;

    &__content {
        text-align: center;
        
        &--bold {
            font-weight: bold;
        }
    }

    &__color {
        height: 12px;
    }

    &__index {
        &__one {
            text-align: start;
        }

        &__five {
            text-align: end;
        }
    }

    &__text {
        display: inherit;
        text-align: center;
        margin-bottom: 24px;

        &__circle {
            background-color: #0e4f9f;
            height: 16px;
            width: 16px;
            border-radius: 8px;
            margin: 2px 16px 0 0;
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;