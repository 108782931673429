
.header__user-info {
  // width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  pointer-events: none !important;
  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;