.modal-fap {
    max-width: 830px !important;
    width: 100% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    overflow-y: auto;
}

.modal-content {
    height: 400px;
    overflow-y: scroll;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;