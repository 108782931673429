.graphic-popover {
    transform: none !important;
    flex-direction: row;
    flex-wrap: nowrap;
    width: max-content;

    > .ant-popover-content {
        > .ant-popover-inner {
            background-color: #FFF;
            position: relative;
            width: min-content;
        }
    }

    &__content {
        font-weight: normal;
        width: 100%;
        max-width: 174px;

        &__session {
            font-weight: normal;

            &__divider {
                height: 100%;
            }
        }
        
        &__session:not(:last-child) {
            margin-bottom: 22px;
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;