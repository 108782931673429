@import '../../styles/variables.less';

.alerts {
  padding: 16px 24px;
  background-color: @dark-5;
  // margin-bottom: 40px;
  border-radius: 4px;

  &__header {
    margin-bottom: 32px;

    .ant-typography {
      margin-bottom: 0px !important;
    }

    &__count {
      font-weight: 600;
    }
  }

  &__listing {
    min-height: 334px;
    padding-left: 12px;

    >.ant-carousel {
      >.slick-slider {
        >.slick-list {
          margin-bottom: 32px;
        }

        >.slick-dots {
          position: relative;
          bottom: 0;
          margin: 0px;

          >li {
            width: 20px;
            height: 20px;

            >button {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            &.slick-active {
              >button {
                background-color: @primary;
              }
            }

            &:not(.slick-active) {
              >button {
                background-color: #121212;
              }
            }
          }
        }
      }
    }

    &__item:not(:last-child) {
      margin-bottom: 18px;
    }

    &__item {
      align-items: stretch;
      border: 2px solid @alert;
      border-radius: 4px;

      &__icon {
        background: @light;
        display: flex;
        align-items: center;
        justify-content: center;

        >span {
          color: @alert;
          font-size: 24px;
        }
      }

      &__data {
        &__container {
          background-color: @light;
          padding: 12px 0px 12px 12px;

          >.ant-row {
            margin: 0px !important;
          }
        }
      }

      &__arrow {
        &__container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 20px;
          cursor: pointer;
        }
      }
    }
  }
}

.project-revalue-cancel-tooltip {
  .ant-tooltip-inner {
    width: max-content;
    padding: 14px 12px;
  }

  .cancel-tooltip-message-container {
    margin-bottom: 24px;
    font-size: 14px;

    .icon {
      color: @alert;
      margin-right: 16px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .cancel-btn {
    background-color: @dark-5;
    color: @dark;
    border: none;

    &:hover {
      color: @dark;
      opacity: 0.5;
    }
  }

  .ant-btn-dangerous {
    background-color: @alert;
  }
}


@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;