.project-modal-menu {
    margin-right: 40px;

    >.ant-anchor-fixed {
        >.ant-anchor-link {
            >.ant-anchor-link-title {
                margin-right: 40px;
            }
        }
    }

    &__alert {
        color: #E70000;
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;