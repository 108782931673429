@import '../../../../styles/variables.less';

#agregate-measure-impact {
    width: 100%;
}

.composite-measure-modal {
    margin-top: 48px;
    width: 100%;
    position: relative;

    .ant-form-item-control-input-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
        gap: 24px;
    }

    @media screen and (max-width: (@mobile-width - 1px)) {
        display: flex;
        flex-direction: column;

    }

    &::after {
        position: absolute;
        content: '';
        border-top: 1px solid #e0e0e0;
        // eaeaea
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        top: 0;
    }

    &::before {
        position: absolute;
        content: '';
        border-top: 1px solid #e0e0e0;
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &-box {
        border: 1px solid #e0e0e0;
        margin: 18px 0;
        width: 100%;
        background-color: #fbfbfb;
        text-align: center;
        padding: 20px 0;

        &-number {
            font-size: 32px;
            font-weight: 400;
        }

        &-title {
            color: @dark-80;
            font-size: 16px;
            text-transform: uppercase;
        }
     
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;