@import "../../../../styles/variables.less";

.project-modal-card {
    border-radius: 4px;
    padding: 24px;

    &__default {
        border: 2px solid @dark-20;
        background-color: @dark-2;
    }

    &__alert {
        border: 2px solid @alert-60;
        background-color: #ff00001a;
    }

    &__text {
        text-align: center;
    }

    .ant-btn-primary {
        .ant-btn-dangerous {
            color: #FFFFFF !important;
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;