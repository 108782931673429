@import '../../../../styles/variables.less';
.impact-you-antecipate-modal {
    margin-top: 34px;

    h1 {
        margin-bottom: 0;
    }
  
    .impact-you-antecipate-modal-body {
        width: 100%;
    }
}
.ant-tooltip-inner {
    min-width: 500px !important;
}
svg {
    padding-top: 4px !important;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;