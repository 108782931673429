.project-modal {
    width: 80vw !important;
    max-width: 830px !important;

    >.ant-modal-content {
        height: 100%;
        padding: 32px 48px 28px;

        >.ant-modal-body {
            height: calc(100% - 36px);
        }
    }

    &__inner {
        height: 60vh;

        &__content {
            max-height: 100%;
            overflow-y: auto;

            scroll-behavior: smooth;

            >#risk {
                min-height: 60vh;
                flex-direction: column;
            }

        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;