@import '../../../styles/variables.less';
.modal-project-areas {
  max-width: 830px !important;
  width: 100% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  overflow-y: auto;

  .ant-modal {
    &-content {
      padding: 32px !important;
      border-radius: 4px !important;
      .ant-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
        .modal-project-areas-title {
            font-size: 24px !important;
        }
        .modal-project-areas-button {
          display: flex;
          gap: 12px;

          .button-tcmgi {
            background: @dark-5;
            border: none;
            padding: 6px 12px;
            gap: 4px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
          }
        }
    }

    &-body {
      margin: 24px 0;
      display: flex;

      .ant-tabs-tab-btn {
        font-weight: 700;
        line-height: 21px;
      }
      .ant-tabs-ink-bar-animated {
        display: none !important;
    }
    }

    // &-footer {
    //   button {
    //     color: @dark-80;
    //   }
    // }
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      color: @dark-80;

      &:hover {
        color: @primary;
      }
    }

    .ant-tabs-ink-bar {
      background: @gradient-primary;
    }
  }
}

@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;