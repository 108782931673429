@import "../../../styles/variables.less";

.areas-tab {
  max-height: 50vh;
  overflow-y: auto;

  .form-col {
    margin-right: 0 !important;
    padding-top: 8px;
  }

  &-table {
    .ant-table-thead .ant-table-cell {
      background-color: @dark-5 !important;
    }

    th,
    td {
      text-align: center !important;
      border: none !important;
      color: @dark !important;
    }

    th:before {
      background-color: @dark-80 !important;
    }

    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -24px;
      width: 100%;
    }
  }
}

.pop-confirm {
  &-confirm-btn {
    background-color: @alert;
    color: @light;
    padding: 4px 12px !important;
    height: auto !important;

    &:hover {
      color: @light !important;
      opacity: 0.8;
    }
  }

  &-cancel-btn {
    border: none;
    background-color: @dark-5;
    color: @dark;
    padding: 4px 12px !important;
    height: auto !important;

    &:hover {
      color: @dark !important;
      opacity: 0.8;
    }
  }

  .ant-popover {
    &-inner {
      max-width: 274px;
      border-radius: 4px !important;
    }
  }
}

@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;