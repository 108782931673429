@import "../../../../styles/variables.less";

.project-modal-title {
    &__title {
        margin: 0 !important;
    }
    &__info {
        padding: 6px 12px;
        background-color: @dark-5;
        margin: 0 !important;
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;