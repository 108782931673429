@import '../../../styles/variables.less';

.menu-sidebar-project {
    height: max-content !important;
    width: 200px !important;
}

.mobile-menu-project {
    width: 50px !important;
    overflow-x: hidden;
}

.mobile-menu-button {
   left: 35px;
   bottom: 150px;
    position: absolute;
    width: max-content !important; 

    @media screen and (max-width: (@mobile-width - 1px)) {
        left: 15px;
        bottom: 250px;
    }
}

.ant-anchor {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        background: transparent !important;
        border-right: 2px solid #eaeaea;
        height: 100vh;
        transition: all 0.1s;
        border-inline-start: unset !important;
    }

    .ant-anchor-link {
        padding-inline: 0 !important;
        padding-left: 0 !important;
        width: 100% !important;
        height: max-content;
    
        &:hover {
            background: transparent !important;
            border-right: 2px solid @primary;
            height: max-content;
            width: max-content;
            transition: all 0.1s;
            z-index: 99;
            position: relative;
        }
    
        .ant-anchor-link-title {
            white-space: pre-wrap !important;
            text-overflow: unset;
            color: @dark-80;
            width: 150px;
        }
    }
}

.ant-anchor-ink-visible {
    display: none !important;
}


@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;