@import '../../styles/variables.less';

.auth-login {
  background-image: linear-gradient(91.59deg, rgba(60, 186, 146, 0.8) 0%, rgba(3, 158, 109, 1) 100%), url(../../../public/images/instituto-raiz.jpg);
  position: absolute;
  height: 100vh;
  width: 100%;
  .background-position();
  top: -64px;

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: -60px;

    &-box {
      margin-top: 20px;
      background: @light;
      width: 480px;
      height: 350px;
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);
      border-radius: @radius-4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 48px 96px;
      gap: 32px;

      .ant-form-item-control-input {
        border-bottom: 1px solid #3CBA92;
        &:hover {
          border-inline-end-width: unset !important;
        }
      }

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-input, .ant-input-password {
          border: 0 !important;

           &:focus {
            outline: 0 !important; 
            box-shadow: unset;
            border-inline-end-width: unset;
            border: 0 !important;
          }
        }

        .ant-input-password {
          &:focus, &:active, &:hover {
            outline: 0 !important;
            box-shadow: unset;
            border-inline-end-width: unset;
            border: 0 !important;
          }
        }
      }
    }

    .recovery-password {
      color: @primary;
    }
  }

}

.ant-input::placeholder {
  color: @dark !important; 
  font-weight: 600;
}


span {
  > a {
    color: @primary;


    &:hover {
      opacity: 0.9;
    }

  }
}

@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;