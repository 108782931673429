@import "../../../../../styles/variables.less";

.project-modal-form-item {

    span,
    label {
        color: @dark-80 !important;
    }

    .revaluate-alert {
        span {
            color: @alert-60 !important;
            font-size: 16px;
            margin-right: 4px;
        }
    }

    >.ant-form-item-row {
        >.ant-form-item-label {
            >label {
                font-size: 14px;

                &::before {
                    display: none !important;
                }
            }
        }
    }

    .ant-input-number {
        background: @dark-5;
        border: none;
        outline: 0;
        width: 101%;
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;