@import "../../../../styles/variables.less";

.cancel-tooltip {
    >.ant-tooltip-content {
        >.ant-tooltip-inner {
            width: fit-content;
        }
    }

    &__inner {
        width: max-content;

        &__text {
            &__icon {
                color: @alert;
                margin-right: 4px;
            }
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;