@import "../../styles/App.less";

.modal-institutions-areas {
  max-width: 782px !important;
  width: 100% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;

  .ant-modal {
    &-content {
      padding: 32px !important;
      border-radius: 4px !important;
    }

    &-body {
      margin: 24px 0;
    }

    &-footer {
      button {
        color: @dark-80;
      }
    }
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      color: @dark-80;

      &:hover {
        color: @primary;
      }
    }

    .ant-tabs-ink-bar {
      background: @gradient-primary;
    }
  }
}

@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;