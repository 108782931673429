@import './variables.less';

@import '~antd/dist/reset.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'sans-serif';
}

.page {
  padding-top: @layout-header-height;
  min-height: 100vh;

  @media screen and (min-width: @mobile-width) {
    &.auth {
      transition: all 0.2s;

      &.collapsed {
        padding-left: 80px;
      }
    }
  }

  >.ant-layout-sider {
    margin-top: -64px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    z-index: 100;
    left: 0;
    height: 100vh;
    position: fixed;

    .ant-layout-sider-zero-width-trigger {
      top: 0;
      line-height: 55px;

      .anticon {
        font-size: 22px;
        
      }
    }

    @media screen and (max-width: (@mobile-width - 1px)) {
      position: fixed;
      left: 0;
      top: 0;
      margin-top: 0;
      height: 100vh;
      z-index: 110;
    }

    .logo-container {
      max-width: 200px;
      height: 60px;
      text-align: center;
      padding-top: 10px;
      display: flex;
      justify-content: center;
      line-height: 94px !important; 

      img {
        max-width: 90%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .go-back-btn {
    padding: 4px 0;
  }

  .ant {
    &-layout {
      background-color: @layout-body-background;
      &-header {
        position: fixed;
        width: 100vw;
        top: 0;
        padding: 0 40px;
        z-index: 100;
        background-color: @layout-header-background;
        box-shadow: 10px 0 10px rgb(0 0 0 / 20%);
        line-height: 64px !important; 
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: @desktop-width) {
          padding: 0 40px;
        }

        > *:not(.logo-container) {
            float: right;
        }

        &.auth {
          width: 100%;
          padding: 0 40px;
          height: 94px;
          transition: all 0.2s;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 94px !important; 

          &.collapsed {
            width: 100%;

            @media screen and (min-width: @mobile-width) {
              width: calc(100% - 80px);
            }
          }
        }

       .menu-desktop {
          @media screen and (max-width: @mobile-width) {
            display: none !important;
          }
        }

        ul.ant-menu {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          border: 0;

          li {
            &::after {
              border-bottom: none !important;
            }

            a {
              color: @dark;
              line-height: 16px;
            }
            .active-link {
              color: red !important
            }
          }
          
          .profile-menu {
            margin-right: 0;

            .anticon {
              padding: 0 14px;
              margin: 0;
              font-size: 22px;
              color: @primary;
            }
          }
        }

        .logo-container {
          max-width: 200px;
          height: 100%;
          display: inline-block;

          @media screen and (max-width: @mobile-width) {
            max-width: 50%;
          }

          img {
            max-width: 100%;
            height: auto;
            margin-top: -5px;
          }
        }
      }

      &-content {
        position: relative;
        
        &.auth {
          //background-color: #F0F2F5;
          margin: 64px 0;
          padding: 0 40px;
          @media screen and (max-width: @mobile-width) {
            padding: 20px 0;
          }
        }
      }

      &-has-sider {
        .ant-layout {
          &-content {
            @media screen and (min-width: @desktop-width) {
              padding: 40px 0 40px calc(50vw - @desktop-width/2 - 40px);
            }
          }

          &-sider {
            background-color: @layout-body-background;

            .helper {
              display: inline-block;
              height: 100%;
              vertical-align: middle;
            }

            img {
              width: 100%;
              vertical-align: middle;
            }

            @media screen and (max-width: @mobile-width) {
              display: none;
            }
          }
        }
      }

      &-footer {
        position: relative;
        box-shadow: 10px 0 10px rgb(0 0 0 / 20%);
        text-align: center;
        color: @dark;
        background-color: @layout-footer-background;
        padding: 20px calc((100vw - @desktop-width)/2);
        display: flex;
        flex-direction: column;

        .raiz-footer-year {
          margin-bottom: 16px;
        }

        @media screen and (max-width: @desktop-width) {
          padding: 20px 40px;
        }
        > div {
          height: 100px;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .content {

    &-title,
    &-body {
      padding: 0 40px 5px;
    }
  }
}

.profile-menu-popup {
  position: fixed !important;

  .anticon {
    margin: 0 0 1em;
  }
  .ant-btn {
    padding: 0;
  }

  .header-info-user {
    pointer-events: none;
  }

  span {
    color: @dark-80 !important;
  }
}

.ant-tooltip-inner {
    color: #2D2D2D !important;
}

.btn-lg {
  padding: 12px;
  width: 288px;
  height: 40px;
}

.buttonLogout {
  background: @alert-60 !important;
}

//reset antd title
.ant-modal-title {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: @gradient-primary;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  padding: 12px 0 12px 16px;
  color: @dark;
  font-size: 1.4rem;
}

//reset antd btn
.ant-btn-primary {
  // .linear-gradient-bg();
  outline: none;
  border: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  > span, a {
    //  color: @light !important;
     font-weight: 400 !important;
     font-size: 14px !important;
  }

  &:hover {
    border: none;
    opacity: 0.8;
  }

  &.ant-btn-dangerous {
    background-color: @alert;
    color: @light;
  }
}

.ant-btn-link {
  &:disabled {
    color: @dark-20
  }

  &.ant-btn-dangerous {
    color: @alert;
  }
}

.menu-burger-button {
  position: absolute;
  width: auto;
  height: auto;
  top: 18px;
  right: 32px;

  @media only screen and (min-width: 820px) {
      display: none;
  }
}
.menu-burger {
  @media only screen and (min-width: 811px) {
      display: none;
  }
}

.menu-burger-open {
      display: block !important;
      gap: 24px;
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      right: 0;
      z-index: 9998;
      overflow: auto;
      margin-top: 0;
      top: 94px;
      background: @light;

}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  background-color: @primary;
}

// Scroll bar
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
  height: 20px !important;
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #dad7d7;
}

.ant-notification {
  top: 0 !important;
}

// Input
.input-default {
  background-color: @dark-5 !important;
  border: none !important;
  padding: 6px 12px;
  border-radius: 4px !important;

  &::placeholder {
    color: @dark-80 !important;
    font-weight: 400 !important;
    border-radius: 4px !important;
  }
}

.ant-select-arrow, .ant-input::placeholder {
  color: @dark-80 !important;
}

// Select

.ant-select-selector, .ant-input {
  background: @dark-5 !important;
  border: none !important;

  .ant-select-selection-placeholder{
      color: @dark-80;
  }
}