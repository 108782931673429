.operational-area-split {
  height: 100%;

  &__title {
    &__container {
      height: fit-content;
    }
  }

  &__graphic {
    height: 630px;
    overflow-y: auto;
  }
}

@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;