.notfound-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;

    .notfound-content {
        position: absolute;
        width: 100%;
        top: calc(50% - 85px);
        left: 0;
        .content-title {
            .ant-typography+h2.ant-typography {
                margin-top: 0;
                margin-bottom: 1.1em;
            }
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;