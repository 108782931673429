@import "../../../../../styles/variables.less";

.project-modal-field {
    &__label {
        margin: 0px 0px 8px 0px !important;
        font-size: 14px;
        color: @dark-80;
        font-weight: 400;
    }

    &__value {
        margin: 0px 0px 32px 0px !important;
        font-size: 16px;
        color: @dark-80;
        font-weight: 600;

        >span {
            color: @primary;

            &.anticon-swap-right {
                color: @dark-80;
            }
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;