.session-title {
    text-align: start;
    border-width: 3px;
    border-style: solid;
    border-image:
        linear-gradient(to bottom,
            #3CBA92,
            #039E6D) 1 100%;
    border-right: none;
    padding: 12px 16px;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;