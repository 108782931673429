.ant-pagination {
    display: flex;
    justify-content: flex-end;
}

.ant-form-item-control-input-content {
    input {
        text-align: left !important;
    }
}

.list-user {
    width: 100%;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-btn {
            width: 230px;
        }
    }

    .ant-table-wrapper {
        padding-top: 24px;
    }

    .dashboard__filter {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-itens {
            display: flex;
            gap: 16px;
            &-box {
                width: 400px;
            }
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;