@layout-header-height: 64px;
@desktop-width: 1200px;
@mobile-width: 768px;

.linear-gradient-bg() {
    background: linear-gradient(91.59deg, #3CBA92 0%, #039E6D 100%);
    border-radius: 4px;
}

.background-position() {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

// colors
@primary: #3CBA92;
@primary-strong: #008000;
@light: #ffffff;
@dark: #2D2D2D;
@alert: #E70000;
@gradient-primary: linear-gradient(91.59deg, #3CBA92 0%, #039E6D 100%);

//opacity-colors
@primary-25: rgba(64, 201, 158, 0.25);
@dark-80: rgba(45, 45, 45, 0.8);
@dark-20: rgba(45, 45, 45, 0.2);
@dark-5: rgba(45, 45, 45, 0.05);
@dark-2:  rgba(45, 45, 45, 0.02);
@light-80: rgba(255, 255, 255, 0.8);
@alert-60: rgba(255, 0, 0, 0.6);
@alert-25: rgba(255, 0, 0, 0.25);

@radius-4: 4px;

@shadow-box-light: 2px 2px 16px rgba(0, 0, 0, 0.1);
