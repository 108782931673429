@import '../../styles/variables.less';

.overview {
  border-radius: @radius-4;
  background: @gradient-primary;
  box-shadow: @shadow-box-light;
  padding: 16px 24px;
  margin-bottom: 48px;

  &__title,
  &__section>.ant-typography {
    color: @light !important;
  }

  &__title {
    margin-bottom: 32px !important;
  }

  &__section {
    text-align: center;

    >h1 {
      font-size: 48px;

      .ant-spin-dot-item {
        background-color: @light;
      }
    }

    >.ant-typography {
      margin-bottom: 16px;
    }
  }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;