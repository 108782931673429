@import '../../../../styles/variables.less';

.outcomes-modal {
    margin-top: 90px;

    @media screen and (max-width: (@mobile-width - 1px)) {
        margin-top: 128px;
    }

    h1 {
        margin-bottom: 0;
    }

    &-body {

        &-label, span {
            color: @dark-80;
        }

        &-content {
            display: flex;
            flex-direction: column;

            .ant-input-number {
                width: 100%;
                background: @dark-5;
                border: none;
                outline: 0;
            }

            &-select-unique {
                width: 100%;
                background: @dark-5 !important;
            }
        }
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;