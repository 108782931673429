@import '../../styles/variables.less';

.dashboard {
  &__title {
    text-align: center;
    padding: 12px 0;
    border-radius: @radius-4;
    background: @gradient-primary;
    box-shadow: @shadow-box-light;
    color: @light !important;
    margin-bottom: 40px !important;
  }

  &__filter {
    padding: 12px 20px;
    border-radius: @radius-4;
    background: @gradient-primary;
    box-shadow: @shadow-box-light;
    color: @light !important;
    margin-top: 40px !important;
    margin-bottom: 15px;
  }

  .image-raiz {
    text-align: center;

    img {
      width: 90px;
      margin-bottom: 15px;
      top: -10px;
      position: relative;
    }
  }
}

.col-bottom {
  margin-top: 25px;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;