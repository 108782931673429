@import '../../../../styles/variables.less';

.alignement-modal {
    width: 100%;
    height: 100%;
    position: relative;

    .alignement-modal-body {
        height: 100%;
        display: flex;

        &-label {
            color: @dark-80 !important;
        }
        
        .ant-select-selector, .ant-input {
            background-color: @dark-5 !important;
            border: none !important;

            .ant-select-selection-placeholder{
                color: @dark-80;
            }

        }

        .ant-select-arrow, .ant-input::placeholder {
            color: @dark-80 !important;
        }

        &-select-unique {
            width: 100%;
        }
    }
}

.ant-select-item {
    background: transparent !important;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;