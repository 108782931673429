@import '../../../../styles/variables.less';

.project-data-modal {
    position: relative;
    width: 100%;
    padding-bottom: 24px;
    height: max-content;

    .ant-space {
        .ant-space-item {
            width: 100% !important;
        }
    }

    .ant-input .add-direction {
        width: 350px !important;
        background: red !important;
    }

    .project-data-modal-body {
        height: 100%;
        display: flex;

        .ant-select-selector, .ant-input {
            background-color: @dark-5 !important;
            border: none !important;

            .ant-select-selection-placeholder{
                color: @dark-80;
            }
        }

        .ant-select-arrow, .ant-input::placeholder {
            color: @dark-80 !important;
        }

        &-select-unique {
            width: 100%;
        }

        &-popover {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
        }
    }
}

.ant-select-item {
    background: transparent !important;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;