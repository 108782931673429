.risk-assessment-modal {
    margin-top: 32px;
    margin-bottom: 48px;

    .risk-assessment-modal-body {
        width: 100%;
    }
 
    .risk-assessment-modal-body-popover {
        display: flex;
        align-items: center;

        .anticon-info-circle {
            padding-top: 8px !important;
        }
    }
    .risk-assessment-modal-body-checkbox {
        display: flex;
        gap: 4px;
        align-items: center;
    }
}
.ant-tooltip-inner {
    min-width: 450px !important;
}

svg {
    padding-top: 4px !important;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;