@import '../../../../styles/variables.less';

.area-project-modal {
    width: 100%;
    height: 500px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: thin;
    scrollbar-color: @primary #ffffff;
    scroll-behavior: smooth;

    #risk {
        min-height: 500px;
    }

    span {
        color: @dark-80 !important;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between !important;
    }

    &-select-title {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    &-select-unique {
        width: 100%;
    }

    .ant-form-item {
        margin-top: 8px !important;
        margin-bottom: 0 !important;
    }
    
    .ant-input-number {
        width: 100%;
        background: @dark-5;
        border: none;
        outline: 0;
    }
    .ant-select-selector, .ant-input {
        background-color: @dark-5 !important;
        border: none !important;

        .ant-select-selection-placeholder{
            color: @dark-80;
        }
    }
    .ant-select-arrow, .ant-input::placeholder {
        color: @dark-80 !important;
    }

}

.ant-anchor-wrapper {
    max-height: 500px;
    overflow-y: auto;
}

.ant-select-item {
background: transparent !important;
}


.ant-input::placeholder {
    font-weight: 400 !important;
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;