@import '../../../../styles/variables.less';

.output-modal {
    margin-top: 72px;
    width: 100%;
    height: 100%;
    position: relative;
    
    h1 {
        margin-bottom: 0;
    }

    &-body {
        margin-top: -24px;
    }

    @media screen and (max-width: (@mobile-width - 1px)) {
        margin-top: 120px;

        &-body {
            margin-top: -48px;
        }
    }
}


@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;