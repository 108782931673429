@import "../../../../styles/variables.less";

.project-edit-modal-card {
    border-radius: 4px;
    padding: 24px;
    border: 2px solid @dark-20;
    background-color: @dark-2;

    span {
        color: @alert-60 !important;
        font-size: 16px;
        margin-right: 4px;
    }

    &__text {
        text-align: center;
    }
}
@primary-color: #3CBA92;@menu-bg: #ffffff;@layout-body-background: #ffffff;@layout-footer-background: #ECECEC;@layout-header-background: #ffffff;@layout-trigger-color: #002140;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;